.link-navbar {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #777;
  font-size: 14px;
  margin: 0 10px;
}

.container-navbar {
  padding: 10px 10vw !important;
  width: 100%;
  background-color: #fff !important;
  margin: 0 auto;
  border-bottom: 1.2px solid #ccc;
  display: flex;
  z-index: 100;
}

.container-link {
  display: flex;
  margin-left: auto;
}
.link-navbar {
  padding: 5px 50px;
}
