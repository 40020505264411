@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../_metronic/_assets/fonts/HelveticaNeue-Roman.otf")
    format("opentype");
}

td {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9vw;
}

.total-record-container {
  margin-top: 10px;
  width: 100%;
}
.total-record-container .items {
  display: flex;
  align-items: center;
}
.response-success{
  margin: 10px 0;
  color: green;
  text-align: left;
}
.response-error{
  margin: 10px 0;
  color: red;
  text-align: left;
}
.response-warning{
  margin: 10px 0;
  color: #f8bb86;
  text-align: left;
}

.container-donation-list {
  padding: 50px 9vw;
  z-index: 99;
}
.container-donation-list h1 {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 30px;
}

.info-download {
  color: #337ab7;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.icon-download {
  color: #337ab7;
}

.link-download:hover {
  color: rgb(48, 48, 177);
}

.upload-add-row-container {
  width: 100%;
  border: solid 1px #ccc;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.upload-add-form {
  margin: 0 5px;
  width: 20%;
  min-width: 9vw;
}

.react-bootstrap-table {
  overflow-x: auto;
}

svg {
  height: 1rem !important;
  width: 1rem !important;
  margin-left: 1px;
  cursor: pointer;
}

.react-bootstrap-table-page-btns-ul { 
  float: right!important;
}