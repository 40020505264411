.container-form-upload {
  width: 100%;
  margin-top: 3vh;
  padding: 20px 10vw;
}

.container-form-upload .section-card {
  background-color: #fff;
  padding: 20px;
}

.container-form-upload .section-card input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.container-form-upload h2 {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 30px;
  margin-bottom: 0px;
}

.container-form-upload p {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px;
}

.info-upload {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 11px;
}

.submit-upload {
  font-size: 14px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #337ab7;
  border-color: #2e6da4;
  width: 70px;
  margin-top: 15px;
}

.submit-upload:hover {
  background-color: blue;
}

.card-options {
  cursor: pointer;
  margin-bottom: 5px;
  padding: 10px;
}

.card-options:hover {
  background-color: #f7f7f7;
}

.card-selected {
  background-color: #f2f2f2;
}

.card-title {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-bottom: 5px !important;
}

.card-text {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.batch-form-container {
  width: 100%;
  display: inline-block;
}

.row-batch-container {
  width: 100%;
  max-height: 30vh;
  overflow-y: scroll;
}

.empty-placeholder {
  padding: 10px;
  text-align: center;
}

.row-batch {
  width: 100%;
  border: solid 1px #d1e7ff;
  padding: 10px;
  margin-top: 10px;
}

.batch-col {
  display: flex;
  align-items: center;
}

.field-name {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
}

.data-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
